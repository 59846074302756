<template>
    <div>
        <v-row no-gutters>
            <template v-for="(eviction, index) in evictions">
                <v-col lg="4" md="3" sm="6" :key="index">
                    <screen-eviction
                        :eviction="eviction"
                        mode="print"
                    ></screen-eviction>
                </v-col>
            </template>
        </v-row>
    </div>
</template>
<script>
import WithCrimeRecords from "@components/screen/mixins/WithCrimeRecords";

export default {
    name: "applicant-evictions",
    mixins: [WithCrimeRecords],
    components: {
        ScreenEviction: () =>
            import("@components/screen/components/Search/ScreenEviction"),
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {
        this.records = this.items.filter(
            (item) => this.$_.get(item, "type", "") === "eviction"
        );
    },
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>